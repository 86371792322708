/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/jquery-ui-dist@1.13.1/jquery-ui.min.js
 * - /npm/tether@2.0.0/dist/js/tether.min.js
 * - /npm/js-cookie@3.0.5/dist/js.cookie.min.js
 * - /npm/sprintfjs@1.2.17/sprintf.min.js
 * - /npm/jsrender@1.0.11/jsrender.min.js
 * - /npm/jquery-validation@1.19.3/dist/jquery.validate.min.js
 * - /npm/jquery-validation@1.19.3/dist/additional-methods.min.js
 * - /npm/popper.js@1.16.1/dist/umd/popper.min.js
 * - /npm/jquery-migrate@3.4.0/dist/jquery-migrate.min.js
 * - /npm/moment@2.29.3/moment.min.js
 * - /npm/moment-timezone-all@0.5.5/builds/moment-timezone-with-data.min.js
 * - /npm/jquery-timepicker@1.3.3/jquery.timepicker.js
 * - /npm/jssha@3.2.0/dist/sha.min.js
 * - /npm/select2@4.0.13/dist/js/select2.min.js
 * - /npm/overlayscrollbars@1.13.1/js/OverlayScrollbars.min.js
 * - /npm/he@1.2.0/he.min.js
 * - /npm/timezone-picker@2.0.0-1/dist/timezone-picker.min.js
 * - /npm/bootstrap@4.6.1/dist/js/bootstrap.min.js
 * - /npm/bootstrap-datepicker@1.10.0/dist/js/bootstrap-datepicker.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
